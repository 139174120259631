import PTZTokens from '@petz/ds-tokens/petz';
import { Component, h, Host, Prop, State } from '@stencil/core';
import { capitalizeFirstLetter } from '../../utils/utils';
import { PTZBrandConfig } from './types/ptz-brand.enums';
import { PTZBrandTypes } from './types/ptz-brand.types';

@Component({
  tag: `ptz-brand`,
  styleUrl: `ptz-brand.scss`,
  shadow: false,
})
export class PTZBrand {
  /** Nome da marca */
  @Prop() brandName: PTZBrandTypes.BrandName;

  /** Estado de renderização */
  @Prop() skeleton: boolean = false

  /** Variação de cor da imagem */
  @Prop() variant?: PTZBrandTypes.Variant = PTZBrandConfig.Variant.Primary;

  /** Indica se a brand usa a versão mobile */
  @Prop() mobile?: boolean = false;

  /** Informação sobre a media query */
  @State() matchMedia: MediaQueryList;

  /** A versão da tela é mobile ou desktop */
  @State() isMobile: boolean = window.matchMedia(`(max-width:${PTZTokens.breakpointMd})`).matches;

  private handleMatchMediaChange() {
    this.isMobile = this.matchMedia.matches;
  }

  componentWillLoad() {
    this.matchMedia = window.matchMedia(`(max-width:${PTZTokens.breakpointMd})`);
    this.matchMedia.onchange = this.handleMatchMediaChange.bind(this);
  }

  disconnectedCallback() {
    this.matchMedia.onchange = null;
  }

  private cdnBaseUrl = 'https://static-file.petz.com.br/platforms/img/ds';

  render() {
    const mobileVersion = this.isMobile && this.mobile ? '-mobile' : '';

    const brandSizeDesktop = {
      petz: { w: 118, h: 26 },
      seres: { w: 73, h: 18 },
      adotepetz: { w: 101, h: 24 }
    }

    const brandSizeMobile = {
      petz: { w: 72, h: 16 },
      seres: { w: 73, h: 18 },
      adotepetz: { w: 101, h: 24 }
    }

    const brandSize = this.isMobile ? brandSizeMobile : brandSizeDesktop

    return (
      <Host>
        {this.skeleton ?
        <ptz-skeleton width={brandSize[this.brandName].w} height={brandSize[this.brandName].h} rounded="2xs" />:
        <img
          data-testid={`${this.brandName}-logo`}
          src={`${this.cdnBaseUrl}/${this.brandName}/logo-${this.brandName}-${this.variant}${mobileVersion}.svg`}
          alt={`Logo da marca ${capitalizeFirstLetter(this.brandName)}`}
          class={`ptz-brand ptz-brand-${this.brandName}`}
          width={brandSize[this.brandName].w}
          height={brandSize[this.brandName].h}
        />}
      </Host>
    );
  }
}
